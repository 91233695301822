<template>
  <Layout :loading="isLoading">
    <div class="container">
      <div class="home">
        <h1>{{ t('pages.home.title') }}</h1>
        <p class="tac">
          Complétez gratuitement votre profil et votre catalogue en ligne pour
          attirer de nouveaux clients à la recherche d'articles écoresponsables.
        </p>
        <div>
          <p>Et dès janvier 2022, bénéficiez de 4 nouveaux services optionnels :</p>
          <ol>
            <li>Une Marketplace durable et locale ;</li>
            <li>Une carte de fidélité inter-prestataires ;</li>
            <li>Des promotions immédiates sur les articles de votre choix ;</li>
            <li>Et des petites annonces pour informer vos clients de vos événements et nouveautés !</li>
          </ol>
        </div>
        <p>Vous ne trouvez pas ce que vous cherchez ? Contactez-nous pour obtenir de l’aide.</p>
        <p>
          <a href="mailto:info@lowco.org" class="lowco-button">Contactez-nous</a>
        </p>
        <p>
          <a class="link" :href="LOWCO_PWA_LINK" target="_blank">Retour vers le site</a>
        </p>
        <Divider class="mt-4 mb-4" />
        <AnalyticsStats v-if="currentCompanyId" :companyId="currentCompanyId" />
        <div class="socials">
          <a href="https://www.facebook.com/lowcoapp">
            <FontAwesomeIcon :icon="['fab', 'facebook-f']" />
          </a>
          <a href="https://www.instagram.com/lowco_app">
            <FontAwesomeIcon :icon="['fab', 'instagram']" />
          </a>
          <a href="https://www.youtube.com/channel/UC4GTSe4eGxk1OuBPJxSEZwA">
            <FontAwesomeIcon :icon="['fab', 'youtube']" />
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import Layout from '@/components/common/Layout.vue';
import Divider from '@/components/common/Divider.vue';
import AnalyticsStats from '@/components/profile/AnalyticsStats.vue';

import useCurrentCompany from '@/composables/useCurrentCompany';

library.add(
  faFacebookF,
  faInstagram,
  faYoutube,
);

const LOWCO_PWA_LINK = process.env.VUE_APP_POST_LOGOUT_REDIRECT;

export default defineComponent({
  name: 'Home',
  components: {
    FontAwesomeIcon,
    Layout,
    AnalyticsStats,
    Divider,
  },
  setup() {
    const { t } = useI18n();
    const { isFetchingCompanies, currentCompanyId } = useCurrentCompany();

    // eslint-disable-next-line global-require
    const certificationImage = computed(() => require('@/assets/images/lowco-certification.png'));

    return {
      t,
      LOWCO_PWA_LINK,
      isLoading: isFetchingCompanies,
      currentCompanyId,
      certificationImage,
    };
  },
});
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  margin: 2rem 0;
}

.socials {
  a {
    font-size: 3rem;

    & + a {
      margin-left: 2rem;
    }
  }
}

.lowco-button {
  display: inline-block;
}

.certification {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    width: 15rem;
    height: 15rem;
  }

  &__download {
    margin: 2rem;
    font-size: 1.5rem;
  }
}
</style>
